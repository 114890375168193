import Logo from "../../public/assets/Logo.svg";

export default function Index() {
  return (
    <div className="flex justify-center flex-col min-h-screen items-center border border-black">
      <h1>Welcome to Remix</h1>
      <img src="./GOOD(1).png" alt="My logo" />
    </div>
  );
}
